@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif !important;
}


.timeline .checkpoint {
    padding-left: 1.3em;
    padding-bottom: 4em;
    border-left: 0.5px solid #ffffff80;
    position: relative;
}

.timeline .checkpoint:last-child {
    padding-bottom: 0em;
}

.timeline .checkpoint::before {
    position: absolute;
}

.timeline .checkpoint div::before{
    position: absolute;
    content: '';
    /* box-shadow: 0 0 0.5em #0d71fc; */
    background: #020a13;
    border-radius: 100px;
    width: 1em;
    height: 1em;
    top: 0%;
    left: -0.52em;
    border: 1.5px solid  #fff;
}

.sidenav {
    right: 0;
    animation: sidenav 300ms ease-in-out;
    overflow-x: hidden;

}

@keyframes sidenav {
    from{
        transform: translateX(550px);
    }

    to {
        transform: translateX(0px);
    }
}